// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// normalize CSS across browsers
import "./src/css/normalize.css"

// custom CSS styles
import "./src/css/style.css"
import { Amplify } from 'aws-amplify';
import amplifyconfig from "./src/amplifyconfiguration.json";
import '@aws-amplify/ui-react/styles.css';
import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { AuthProvider } from './src/data/AuthContext';
import { ModalProvider } from './src/data/ModalContext';
import { ChakraProvider } from "@chakra-ui/react";
import theme from './src/@chakra-ui/gatsby-plugin/theme';

Amplify.configure(amplifyconfig);

export const wrapRootElement = ({ element }) => (

  <Authenticator.Provider>
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <ModalProvider>{element}</ModalProvider>
      </ChakraProvider>
    </AuthProvider>
  </Authenticator.Provider>
);

export const wrapPageElement = ({ element }: { element: React.ReactNode }) => (
  <React.Fragment>
    {element}
  </React.Fragment>
);

