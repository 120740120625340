import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    salbeigruen: {
      50: "#F3F5F3", // Sehr hell (Tint)
      100: "#E1E6E3",
      200: "#CED8CF",
      300: "#BBCBBB",
      400: "#A8BDA8",
      500: "#A4B1A0", // Deine Hauptfarbe
      600: "#889482",
      700: "#6D7864",
      800: "#525C46",
      900: "#383F28", // Sehr dunkel (Shade)
    },
    gedecktbraun: {
      50: "#F2F1EE",
      100: "#DBD8D3",
      200: "#C5BEB8",
      300: "#B0A49D",
      400: "#9B8981",
      500: "#8A8277", // Hauptfarbe
      600: "#70665D",
      700: "#5A4B43",
      800: "#453129",
      900: "#31180F",
    },
  },
  fonts: {
    heading: "'Source Sans Pro', sans-serif",
    body: "'Source Sans Pro', sans-serif",
  },
  components: {
    FormLabel: {
      baseStyle: {
        fontSize: "sm", // Or any appropriate size
      },
    },
  },

},withDefaultColorScheme({ colorScheme: 'salbeigruen' }),
);

export default theme;
