import React, { createContext, useContext, useState, useEffect } from 'react';
import { Hub } from '@aws-amplify/core';
import { getCurrentUser } from 'aws-amplify/auth';

interface AuthProviderProps {
  children: React.ReactNode;
}

interface AuthContextType {
  showAuth: boolean;
  isAuthenticated: boolean;
  openAuthModal: () => void;
  closeAuthModal: () => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [showAuth, setShowAuth] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // add this line

  const openAuthModal = () => setShowAuth(true);
  const closeAuthModal = () => setShowAuth(false);

  useEffect(() => {
    checkInitialAuthStatus();
    const listener = (data: any) => {
      switch (data.payload.event) {
        case 'signedIn':
          setIsAuthenticated(true);
          closeAuthModal();
          break;
        case 'signedOut':
          setIsAuthenticated(false);
          break;
        default:
          break;
      }
    };

    Hub.listen('auth', listener);
    console.log("listening to Hub")
    // return () => {
    //   Hub.remove('auth', listener);
    // };
  }, []);
  const checkInitialAuthStatus = async () => {
    try {
        await getCurrentUser();
        setIsAuthenticated(true);
    } catch (error) {
        setIsAuthenticated(false);
    }
};
  return (
    <AuthContext.Provider value={{
      showAuth, isAuthenticated,
      openAuthModal, closeAuthModal
    }}>
      {children}
    </AuthContext.Provider>
  );
};



export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
