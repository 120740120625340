// src/contexts/ModalContext.tsx
import React, { createContext, useContext, useState } from 'react';

interface ModalContextType {
  isModalOpen: (modalId: string) => boolean;
  openModal: (modalId: string) => void;
  closeModal: (modalId: string) => void;
}

const ModalContext = createContext<ModalContextType>({
  isModalOpen: () => false,
  openModal: () => {},
  closeModal: () => {},
});

interface ModalProviderProps {
  children: React.ReactNode;
}

export const useModal = () => useContext(ModalContext);

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
    console.log(`ModalProviderl: ${children}`);

  const [openModals, setOpenModals] = useState<{ [key: string]: boolean }>({});

  const openModal = (modalId: string) => {
    console.log(`Opening modal: ${modalId}`);
    setOpenModals({ ...openModals, [modalId]: true });
  };

  const closeModal = (modalId: string) => {
    console.log(`Closing modal: ${modalId}`);
    setOpenModals({ ...openModals, [modalId]: false });
  };

  const isModalOpen = (modalId: string) => {
    const isOpen = openModals[modalId] || false;
    console.log(`Is modal ${modalId} open? ${isOpen}`);
    return isOpen;
  };

  console.log('ModalProvider rendered', openModals);

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
